<template>
  <v-container>
    <div class="ml-2">
      <PageTitle class="font font-weight-bold" text="Pump Attendants" />
    </div>
    <div>
      <v-btn
        v-ripple="{ class: 'primary--text' }"
        color="primary"
        class="ml-2 mt-3 white--text font "
        style="font-size: 12px"
        @click="dialog = true"
      >
        New Pump Attendant <v-icon size="20" color="white">mdi-plus</v-icon>
      </v-btn>

      <h4
        style="margin-top: 20px; margin-left: 10px"
        class="font blue-grey--text font"
      >
        List of Pump Attendants
      </h4>
    </div>
    <v-row
      align="center"
      justify="center"
      style="margin-top: 5px; margin-left:1px; margin-right:10px"
    >
      <v-col cols="12" sm="12">
        <template>
          <div>
            <v-data-table
              :headers="headers"
              :items="attendants"
              item-key="product"
              loading-text="Loading... Please wait"
              :loading="loading"
              class="font box-shadow-light "
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    <v-avatar
                      color="blue-grey"
                      style="margin-right: -80px"
                      size="25"
                    >
                      <span class="white--text subtitle-1 ">{{
                        item.name | firstLetterFunction
                      }}</span>
                    </v-avatar>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.isActive | checkStatus }}</td>
                  <td class="justify-left layout px-0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mt-2"
                          tag="a"
                          link
                          @click="openDialog(item)"
                          dark
                          v-on="on"
                          small
                        >
                          <v-icon small color="blue-grey"
                            >mdi-pencil-outline</v-icon
                          >
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          icon
                          class="mt-2"
                          @click="deleteFunction(item)"
                          dark
                          v-on="on"
                          small
                        >
                          <v-icon v-if="!item.isActive" small color="red"
                            >mdi-alert-circle-outline</v-icon
                          >
                          <v-icon v-else small color="success">
                            done_all
                          </v-icon>
                        </v-btn>
                      </template>
                      <span
                        >{{ item.isActive ? "Deactivate" : "Activate" }} Pump
                        Attendant</span
                      >
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </div>
        </template>
      </v-col></v-row
    >
    <v-dialog v-model="dialog" width="400px">
      <v-form ref="form">
        <v-card>
          <v-card-title>
            <span class="font-weight-bold blue-grey--text"
              >Add Pump Attendant</span
            >
            <v-spacer></v-spacer>
            <v-progress-linear
              v-show="progress"
              class="mx-6"
              indeterminate
              color="cyan"
            ></v-progress-linear>
          </v-card-title>
          <v-card-actions>
            <v-spacer
              ><v-form ref="form" class="px-3">
                <v-col>
                  <v-row>
                    <v-text-field
                      outlined
                      label="Name"
                      ref="name"
                      class=" required"
                      v-model="name"
                      :rules="[rules.requiredName]"
                    >
                    </v-text-field>

                    <v-text-field
                      outlined
                      label="Phone"
                      ref="phone"
                      class="required"
                      v-model="phone"
                      :rules="[rules.requiredPhone]"
                    >
                    </v-text-field
                  ></v-row>
                  <v-row>
                    <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                    <!--                    ><v-select-->
                    <!--                      dense-->
                    <!--                      :items="regions"-->
                    <!--                      v-model="region"-->
                    <!--                      ref="region"-->
                    <!--                      label="Region"-->
                    <!--                      outlined-->
                    <!--                      :rules="[rules.requiredRegion]"-->
                    <!--                      class="mx-2 px-2 my-n4 required"-->
                    <!--                    ></v-select-->
                    <!--                  ></v-col>-->

                    <!--                  <v-text-field-->
                    <!--                    dense-->
                    <!--                    outlined-->
                    <!--                    label="Latitude"-->
                    <!--                    class="mx-5 px-2 required"-->
                    <!--                    ref="lat"-->
                    <!--                    v-model="lat"-->
                    <!--                    :rules="[rules.requiredLat]"-->
                    <!--                  >-->
                    <!--                  </v-text-field>-->
                    <!--                  <v-text-field-->
                    <!--                    dense-->
                    <!--                    outlined-->
                    <!--                    label="Longitude"-->
                    <!--                    class="mx-5 px-2 required"-->
                    <!--                    ref="lng"-->
                    <!--                    v-model="lng"-->
                    <!--                    :rules="[rules.requiredLng]"-->
                    <!--                  >-->
                    <!--                  </v-text-field>-->
                  </v-row>
                </v-col>
                <v-row>
                  <v-spacer />
                  <v-btn
                    text
                    class="text-capitalize mr-1"
                    @click="dialog = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    class="text-capitalize"
                    @click.stop="add"
                  >
                    Save
                  </v-btn>
                </v-row>
              </v-form></v-spacer
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <div class="text-center">
      <v-dialog v-model="dialogDelete" max-width="290">
        <v-card>
          <v-progress-linear
            v-show="progress"
            indeterminate
            color="cyan"
          ></v-progress-linear>
          <v-card-title class="blue-grey--text">
            <h5>
              Deactivate this user?
            </h5>
          </v-card-title>

          <v-card-text>
            <h4>{{ people.name }}</h4></v-card-text
          >

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn color="darken-1" text @click="dialogDelete = false">
              No
            </v-btn>

            <v-btn color="red darken-1" text @click="deactivateAttendants()">
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-dialog v-model="dialogUpdate" width="400px">
      <v-card ref="form">
        <v-card-title>
          <span class="ml-6 subtitle-1 font-weight-bold blue-grey--text"
            >Update Pump Attendant</span
          >
          <v-spacer></v-spacer>
          <v-progress-linear
            v-show="progress"
            class="mx-6"
            indeterminate
            color="cyan"
          ></v-progress-linear>
        </v-card-title>
        <v-card-actions>
          <v-spacer
            ><v-form class="px-3">
              <v-col>
                <v-row>
                  <v-text-field
                    outlined
                    label="Name"
                    ref="name"
                    class="mx-5 px-2 required"
                    v-model="itemName"
                    :rules="[rules.requiredName]"
                  >
                  </v-text-field>
                  <v-text-field
                    outlined
                    label="Phone"
                    ref="phone"
                    class="mx-5 px-2 required"
                    v-model="itemPhone"
                    :rules="[rules.requiredPhone]"
                  >
                  </v-text-field
                ></v-row>
                <v-row>
                  <!--                  <v-col cols="12" sm="12" md="12" lg="12"-->
                  <!--                    >-->
                  <!--                  <v-select-->
                  <!--                      dense-->
                  <!--                      :items="regions"-->
                  <!--                      v-model="itemRegion"-->
                  <!--                      ref="region"-->
                  <!--                      label="Region"-->
                  <!--                      outlined-->
                  <!--                      :rules="[rules.requiredRegion]"-->
                  <!--                      class="mx-2 px-2 my-n4 required"-->
                  <!--                    ></v-select-->
                  <!--                  ></v-col>-->

                  <!--                                <v-text-field-->
                  <!--                                        dense-->
                  <!--                                        outlined-->
                  <!--                                        label="Phone"-->
                  <!--                                        class="mx-5 px-2 required"-->
                  <!--                                        ref="phone"-->
                  <!--                                        v-model="itemPhone"-->
                  <!--                                        :rules="[rules.min]"-->
                  <!--                                >-->
                  <!--                                </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Latitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lat"-->
                  <!--                    v-model="itemLat"-->
                  <!--                    :rules="[rules.requiredLat]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                  <!--                  <v-text-field-->
                  <!--                    dense-->
                  <!--                    outlined-->
                  <!--                    label="Longitude"-->
                  <!--                    class="mx-5 px-2 required"-->
                  <!--                    ref="lng"-->
                  <!--                    v-model="itemLng"-->
                  <!--                    :rules="[rules.requiredLng]"-->
                  <!--                  >-->
                  <!--                  </v-text-field>-->
                </v-row>
              </v-col>
              <v-row>
                <v-spacer />
                <v-btn
                  small
                  class=" px-1 mt-n5 mb-4 ml-10"
                  text
                  @click="dialogUpdate = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  small
                  class="blue  px-3 mt-n5 mb-4 ml-5 mr-10 white--text"
                  text
                  @click.stop="save"
                >
                  Save
                </v-btn>
              </v-row>
            </v-form></v-spacer
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="showSnackBar" :timeout="4000" top :color="status">
      <span class="white--text">{{ message }}</span>
      <v-btn text color="white" @click="showSnackBar = false">Close</v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import * as axios from "axios";
export default {
  name: "Products",
  components: { PageTitle },
  data() {
    return {
      progress: false,
      dialog: false,
      formHasErrors: false,
      showSnackBar: false,
      dialogUpdate: false,
      description: "",
      phone: "",
      message: "",
      status: "",
      name: "",
      itemId: "",
      itemName: "",
      itemPhone: "",
      attendants: [],
      dialogDelete: false,
      region: "",
      location: "",
      people: {},
      rules: {
        min: value => value.length >= 10 || "Must be 10 digits",
        requiredName: value => !!value || "Name is required.",
        requiredPhone: value => !!value || "Description is Required."
      },
      loading: true,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 5
      }
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "",
          value: "",
          filterable: false,
          sortable: false
        },
        {
          text: "Name",
          align: "start",
          // sortable: false,
          value: "name"
        },
        { text: "Phone", value: "phone" },
        { text: "Status", value: "isActive" },
        { text: "Actions", value: "" }
      ];
    }
  },
  filters: {
    firstLetterFunction(value) {
      return value.charAt(0).toUpperCase();
    },
    checkStatus(value) {
      return value === null ? "Active" : "Inactive";
    }
  },
  watch: {
    options: {
      handler(value) {
        this.getAttendants(value.page, value.itemsPerPage);
      },
      deep: true
    }
  },

  created() {
    this.getAttendants();
  },

  methods: {
    openDialog(value) {
      this.dialogUpdate = true;
      this.itemId = value.id;
      this.itemName = value.name;
      this.itemPhone = value.phone;
    },

    deleteFunction(item) {
      this.dialogDelete = true;
      this.people = item;
    },

    deactivateAttendants() {
      this.progress = true;
      axios({
        method: "put",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/pump-attendants/${this.people.id}/deactivate`,
        data: {
          isActive: !this.people.isActive
        }
      })
        .then(response => {
          this.dialogDelete = false;
          this.progress = false;
          this.getAttendants();
          if (response.status === 200) {
            this.status = "success";
            this.showSnackBar = true;
            this.message = `Attendant ${
              this.people.isActive ? "deactivated" : "activated"
            } successfully`;
            this.dialogUpdate = false;
          }
        })
        .catch(err => {
          console.log(err);
          if (err.response.status === 401) {
            this.$router.replace({ path: "/login" });
          } else {
            this.status = "danger";
            this.message = err.message;
            this.dialogUpdate = false;
          }
        });
    },

    save() {
      this.progress = true;
      //console.log(this.itemId);
      axios({
        method: "put",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/pump-attendants/${this.itemId}`,
        data: {
          name: this.itemName,
          phone: this.itemPhone
        }
      })
        .then(response => {
          // console.log(response);
          this.progress = false;
          if (response.status === 200) {
            this.status = "success";
            this.message = "Pump attendant updated successfully.";
            //this.message = response.data.message;
            this.showSnackBar = true;
            this.dialogUpdate = false;
            this.getAttendants();
          }
        })
        .catch(err => {
          //  console.log(err);
          if (err.status === 401) {
            this.$router.replace({ path: "/login" });
          }
          this.progress = false;
          this.status = "warning";
          this.showSnackBar = true;
          this.message = "Failed to update this pump attendant.";
        });
    },

    add() {
      if (this.name !== "" && this.phone !== "") {
        this.progress = true;
        setTimeout(() => {
          axios({
            method: "post",
            headers: {
              authorization: `Bearer ${localStorage.getItem("token")}`
            },
            url: `${process.env.VUE_APP_BASE}/pump-attendants`,
            data: {
              name: this.name,
              phone: this.phone
            }
          })
            .then(response => {
              this.getAttendants();
              this.dialog = false;
              this.progress = false;
              if (response.status === 201) {
                this.status = "success";
                this.message = "Pump attendant created successfully.";
                //this.message = response.data.message;
                this.showSnackBar = true;
              }
            })
            .catch(err => {
              if (err.status === 401) {
                this.$router.replace({ path: "/login" });
              } else {
                this.progress = false;
                this.dialog = true;
                this.status = "warning";
                this.message = "Failed to add new pump attendant.";
                //console.log(err);
              }
            });
        }, 1000);
      } else {
        this.showSnackBar = true;
        this.status = "warning";
        this.message = "All fields required.";
      }
    },

    getAttendants() {
      this.progress = true;
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/pump-attendants`
      })
        .then(response => {
          if (response.status === 200) {
            this.progress = false;
            this.loading = false;
            this.attendants = response.data;
          }
        })
        .catch(err => {
          this.loading = false;
          this.progress = false;
          if (err.statusCode === 401) {
            this.$router.replace({ path: "/login" });
          }
        });
    }
  }
};
</script>

<style scoped>
.button {
  background-color: #04a9f3; /* Green */
  border: none;
  color: white;
  padding: 1px 4px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 40px;
  cursor: pointer;
}
#result .v-btn {
  min-width: 65px;
  width: 40px;
}
/* removes grey hover effect */
#result .v-btn::before {
  background-color: transparent;
}

/* adds icon scale hover effect */
#result .v-btn i:hover {
  transform: scale(1.15);
}

.buttonText {
  display: flex;
  flex-direction: row;
}
</style>
